var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"47a4dcbc3aaf725322b9c60036a40b1e6e8e8d97"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import generateConfig from "./configs/config"

const config = generateConfig()

if (config.logging === "true" && config.sentryDSN !== "") {
  const env = config.env
  const sentryDSN = config.sentryDSN

  Sentry.init({
    environment: env,
    dsn: sentryDSN,
    release: "web-app-shopper@" + config.appShaVersion,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.

    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        // Additional SDK configuration goes in here,
      }),
    ],
  })
}
